import React, { useState } from "react";
import '../css/rutas_horario.css'
import { Outlet, Link } from "react-router-dom";
import Carousel from '../components/Carousel'
import Footer from '../components/Footer';
import tarifas from '../assets/images/cta/tarifas.svg'
import reservacion from '../assets/images/cta/reservacion.svg'
//import formulario from '../assets/images/formulario-de-contacto.svg'
import preguntas from '../assets/images/pregutas.png'
import buque_cali from '../assets/images/iconRutasHorario/california-star.svg'
import buque_mexico from '../assets/images/iconRutasHorario/mexico-star.svg'
//import MapaCali from '../components/frames/MapRoutes'
import Booking from "../components/form/Booking";
import mapboxgl from 'mapbox-gl';
import { useTranslation } from 'react-i18next';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapaTopo from '../components/maps/MapaTopo'
import MapaMaza from '../components/maps/MapaMaza'
//import MapaDefault from '../components/maps/Mapa'
// Token de acceso de Mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kaHJhIiwiYSI6ImNsbDJnZTJjbzJnMHAzZW15YnhhOWJkOGEifQ.pP3n_58agJfYHp7fUzrUbw';


const servicios = [ 
    '../images/iconRutasHorario/alimentos.svg',
    '../images/iconRutasHorario/asientos.svg',
    '../images/iconRutasHorario/cabina.svg',
    '../images/iconRutasHorario/camioncito.svg',
    '../images/iconRutasHorario/maleta.svg',
    '../images/iconRutasHorario/mascota.svg',
    '../images/iconRutasHorario/vehiculo.svg',

    // Añade más logos aquí...
  ];

export default function Horario_semana_santa() {
    const { t } = useTranslation('HorarioSanta');
    const [isRutaVisible, setIsRutaVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('La Paz - Topolobampo');
    
    const toggleIsRutaVisible = () => {
        setIsRutaVisible(!isRutaVisible)
    };
        
    //Sin auto
    const itinerario_LP_TP = [
        {
          dia: 'Lunes',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Martes',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viaje programado',          
        },
        {
          dia: 'Sabado',
          embarque: '21:30 HRS',
          salida: '23:00 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viaje programado',
        }
    ];
    const itinerario_TP_LP = [
        {
          dia: 'Lunes',
          embarque: '21:00 HRS',
          salida: '23:59 HRS',
          duracion: '07:30 HRS',
        },
        {
          dia: 'Martes',
          embarque: '21:00 HRS',
          salida: '23:59 HRS',
          duracion: '07:30 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '21:00 HRS',
          salida: '23:59 HRS',
          duracion: '07:30 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '21:00 HRS',
          salida: '23:59 HRS',
          duracion: '07:30 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '21:00 HRS',
          salida: '23:59 HRS',
          duracion: '07:30 HRS',
        },
        {
          dia: 'Sabado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Domingo',
          embarque: '21:00 HRS',
          salida: '23:00 HRS',
          duracion: '09:00 HRS',

        }
    ];
    const itinerario_LP_MZ = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Martes',
          embarque: '17:30 HRS',
        salida: '19:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Miercoles',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Jueves',
          embarque: '17:30 HRS',
          salida: '19:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Sábado',
          embarque: '17:30 HRS',
          salida: '19:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        }
    ];
    const itinerario_MZ_LP = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Martes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Miercoles',        
          embarque: '14:30 HRS',
          salida: '16:00 HRS',
          duracion: '08:00 HRS', 
        },
        {
          dia: 'Jueves',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Viernes',
          embarque: '14:30 HRS',
          salida: '16:00 HRS',
          duracion: '08:00 HRS', 
        },
        {
          dia: 'Sábado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Domingo',
          embarque: '14:30 HRS',
          salida: '16:00 HRS',
          duracion: '08:00 HRS', 
        }
    ];


    // Manejar la selección del usuario
    const handleRouteSelection = (route) => {        
        setSelectedOption(route);
        setIsRutaVisible(!isRutaVisible);        
    };

 
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>{t('ruta_horario_title')}</h3>
                        <p>{t('ruta_horario_title_p')}</p>
                    </div>
                </div>
            </div>
        </section>
        <section style={{background: '#E8E8E8'}}>
            <Booking />
        </section>
        <section className="d-none d-sm-none d-md-block">
            <div className="rutas_horarios">
                <div className="container">  
                    <div className="rutas_horario_tabs">
                        <div className='select_route_ruta_horario'>           
                            <label>{t('seleccionaRuta')}</label>
                            <div className='box_select_route' onClick={toggleIsRutaVisible} >                    
                                <div className='select_ruta__horario'>                               
                                        <label>{selectedOption} </label>
                                        <i class="fi fi-br-angle-small-down"></i>
                                    </div>                                                      
                                <div>
                                    {isRutaVisible && 
                                    <div className='optionListRoutes'>
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazatlán</div>   
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlán - La Paz</div>
                                    </div>
                                    }
                                </div>
                            </div>                              
                        </div>                           
                         
                    </div>                   

                    <div className="container_rutas_horarios">
                        <div className="content_rutas_horarios">
                            
                            <div className="box_rutas_horarios">
                                <div className="header_rutas_horarios">
                                    
                                    <div className="box_selected_rutas_horarios">                                     
                                        
                                    </div>                                                                       
                                </div>
                                <div className="body_rutas_horarios">
                                    {selectedOption === 'La Paz - Topolobampo' && (
                                    <>                                
                                        <table class="table table-bordered tb_tyh">
                                            <tbody>
                                                
                                            <tr>
                                                <th>ABRIL 2025</th>
                                                <th className="tb_tyh_edit"> {t('registro')}</th>
                                                <th className="tb_tyh_edit"> {t('salida')}</th>
                                                <th className="tb_tyh_edit">{t('llegada')}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="dia_semana">{t('text1')}</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>{t('text2')}</td>
                                                <td className="tb_tyh_edit">12:00 HRS</td>
                                                <td className="tb_tyh_edit">13:30 HRS</td>
                                                <td className="tb_tyh_edit">20:30 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>{t('text3')}</td>
                                                <td className="tb_tyh_edit">11:30 HRS</td>
                                                <td className="tb_tyh_edit">13:30 HRS</td>
                                                <td className="tb_tyh_edit">20:30 HRS</td>
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">{t('text17')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td>  
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="dia_semana">{t('text4')}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td>{t('text5')}</td>
                                                <td className="tb_tyh_edit">21:30 HRS</td>
                                                <td className="tb_tyh_edit">23:00 HRS</td>
                                                <td className="tb_tyh_edit">08:00 HRS</td>					        					      
                                            </tr>	
                                            <tr>
                                                <td>{t('text6')}</td>
                                                <td className="tb_tyh_edit">21:00 HRS</td>
                                                <td className="tb_tyh_edit">23:00 HRS</td>
                                                <td className="tb_tyh_edit">08:00 HRS</td>	
                                            </tr>	
                                            <tr>
                                                <td className="dia_semana">{t('text7')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td>  
                                            </tr>	
                                            <tr>
                                                <td colSpan={4} className="dia_semana">{t('text18')}</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>{t('text2')}</td>
                                                <td className="tb_tyh_edit">12:00 HRS</td>
                                                <td className="tb_tyh_edit">13:30 HRS</td>
                                                <td className="tb_tyh_edit">20:30 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>{t('text3')}</td>
                                                <td className="tb_tyh_edit">11:30 HRS</td>
                                                <td className="tb_tyh_edit">13:30 HRS</td>
                                                <td className="tb_tyh_edit">20:30 HRS</td>
                                            </tr>		    
                                                                    
                                            </tbody>
                                        </table>
                                    </>
                                    )}
                                    {selectedOption === 'Topolobampo - La Paz' && (
                                    <>                                
                                        <table class="table table-bordered tb_tyh">
                                            <tbody>
                                                
                                            <tr>
                                                <th>ABRIL 2025</th>
                                                <th className="tb_tyh_edit">{t('registro')} </th>
                                                <th className="tb_tyh_edit">{t('salida')} </th>
                                                <th className="tb_tyh_edit">{t('llegada')}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="dia_semana">{t('text1')}</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>{t('text2')}</td>
                                                <td className="tb_tyh_edit">21:30 HRS</td>
                                                <td className="tb_tyh_edit">23:59 HRS</td>
                                                <td className="tb_tyh_edit">07:30 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>{t('text3')}</td>
                                                <td className="tb_tyh_edit">21:00 HRS</td>
                                                <td className="tb_tyh_edit">23:59 HRS</td>
                                                <td className="tb_tyh_edit">07:30 HRS</td>
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">{t('text17')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td>  
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">{t('text4')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td> 
                                            </tr>
                                            <tr>
                                                <td  className="dia_semana">{t('text7')}</td>                                                
                                            </tr>
                                            <tr>
                                                <td>{t('text5')}</td>
                                                <td className="tb_tyh_edit">21:30 HRS</td>
                                                <td className="tb_tyh_edit">23:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>					        					      
                                            </tr>	
                                            <tr>
                                                <td>{t('text6')}</td>
                                                <td className="tb_tyh_edit">21:00 HRS</td>
                                                <td className="tb_tyh_edit">23:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>	

                                            <tr>
                                                <td colSpan={4} className="dia_semana">{t('text18')}</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>{t('text2')}</td>
                                                <td className="tb_tyh_edit">21:30 HRS</td>
                                                <td className="tb_tyh_edit">23:59 HRS</td>
                                                <td className="tb_tyh_edit">07:30 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>{t('text3')}</td>
                                                <td className="tb_tyh_edit">21:00 HRS</td>
                                                <td className="tb_tyh_edit">23:59 HRS</td>
                                                <td className="tb_tyh_edit">07:30 HRS</td>
                                            </tr>
                                                            

                                                                    
                                            </tbody>
                                        </table>
                                    </>
                                    )}
                                    {selectedOption === 'La Paz - Mazatlan' && (
                                    <>                                
                                          <table class="table table-bordered tb_tyh">
                                            <tbody>
                                                                                     
                                            <tr>
                                                <th>ABRIL 2025</th>
                                                <th className="tb_tyh_edit">{t('registro')} </th>
                                                <th className="tb_tyh_edit">{t('salida')} </th>
                                                <th className="tb_tyh_edit">{t('llegada')}</th>
                                            </tr>
                                            <tr>

                                            </tr>
                                            <tr>
                                                <td className="dia_semana">{t('text20')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td>  
                                            </tr>	 
                                            <tr>
                                                <td colSpan={4} className="dia_semana">{t('text9')}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('text5')}</td>
                                                <td className="tb_tyh_edit">17:30 HRS</td>
                                                <td className="tb_tyh_edit">19:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>{t('text6')}</td>
                                                <td className="tb_tyh_edit">17:00 HRS</td>
                                                <td className="tb_tyh_edit">19:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">{t('text10')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td>  
                                            </tr>	    
                                           
                                            <tr>
                                                <td className="dia_semana">{t('text21')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td>  
                                            </tr>	    
                                            <tr>
                                                <td colSpan={4} className="dia_semana">{t('text19')}</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>{t('text6')}</td>
                                                <td className="tb_tyh_edit">17:00 HRS</td>
                                                <td className="tb_tyh_edit">19:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>{t('text5')}</td>
                                                <td className="tb_tyh_edit">17:30 HRS</td>
                                                <td className="tb_tyh_edit">19:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>
                                                                    
                                            </tbody>
                                        </table>
                                    </>
                                    )}
                                    {selectedOption === 'Mazatlan - La Paz' && (
                                    <>                                
                                        
                                        <table class="table table-bordered tb_tyh">
                                            <tbody>
                                                
                                            <tr>
                                                <th>ABRIL 2025</th>
                                                <th className="tb_tyh_edit">{t('registro')} </th>
                                                <th className="tb_tyh_edit">{t('salida')} </th>
                                                <th className="tb_tyh_edit">{t('llegada')}</th>
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">{t('text22')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td> 
                                            </tr>
                                                       
                                            <tr>
                                                <td colSpan={4} className="dia_semana">{t('text23')}</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>{t('text5')}</td>
                                                <td className="tb_tyh_edit">14:30 HRS</td>
                                                <td className="tb_tyh_edit">16:00 HRS</td>
                                                <td className="tb_tyh_edit">08:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>{t('text6')}</td>
                                                <td className="tb_tyh_edit">14:00 HRS</td>
                                                <td className="tb_tyh_edit">16:00 HRS</td>
                                                <td className="tb_tyh_edit">08:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">{t('text24')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td> 
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">{t('text18')}</td>
                                                <td colSpan={3}  className="tb_tyh_edit">{t('text8')}</td> 
                                            </tr>                                                                                                                                                                                                         
                                            </tbody>
                                        </table>
                                    </>
                                    )}
                                </div>
                            </div>
                            
                        
                            <div className="box_servicios_itinerario">                            
                                <div className="available_services">                                
                                    <h3>{t('text16')} </h3>
                                    <Carousel logos={servicios} />                                                                                                     
                                </div>
                                <div className="box_buque_itinerario">
                                    <div className="available_services_buque">
                                        <div><p>{t('text13')}</p></div>
                                        {selectedOption === 'La Paz - Topolobampo' || selectedOption === 'Topolobampo - La Paz' ?(
                                        <>
                                            <div className="box_available_services">
                                                <img src={buque_cali} alt="buque california star" />
                                                <h4>CALIFORNIA STAR</h4>
                                            </div>
                                            <div><p>{t('text14')} <Link to="/servicios-california-star">{t('text15')}</Link></p></div>
                                        </>):(<>
                                            <div className="box_available_services"><img src={buque_mexico} alt="buque california star" /><h4>MÉXICO STAR</h4></div>
                                            <div><p>{t('text14')}  <Link to="/servicios-mexico-star">{t('text15')}</Link></p></div>
                                        </> )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content_mapa_rutas_horarios">
                            {selectedOption === 'La Paz - Topolobampo' || selectedOption === 'Topolobampo - La Paz' ? (                                
                                <MapaTopo />                                
                            ):(
                                <MapaMaza/>
                            )}
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
        

        <section className="d-block d-sm-block d-md-none">
            <div className="container-rutas-horario">
                <div className="container">
                    
                    <div className="box-select-ruta">
                        <div className="box_select_rutas_horarios phone">               
                            <div className='select_ruta_horario_box_content' onClick={toggleIsRutaVisible} >                                                        
                                <div className='select_ruta_horario phone'>                    
                                    <label>{selectedOption} </label>
                                    <i class="fi fi-br-angle-small-down"></i>
                                </div>      
                            </div>
                            {isRutaVisible && 
                            <div className='optionListRoutes'>
                                <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazatlán</div>   
                                <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlán - La Paz</div>
                            </div>
                            }                                                                                                                                                                                                                
                        </div>
                    </div>

                    <div className="content-rutas-horario">
                        <div className="body-rutas-horario">
                            {selectedOption === 'La Paz - Topolobampo' &&(
                            <>                                                                                    
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text1')}</h3>
                                        <div className="item-hora-itinerario"><label>{t('registro')}: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('salida')}: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('llegada')}: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Viernes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text17')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                         
                                    </div>
                                ))}

                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Sabado')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text4')}</h3>
                                        <div className="item-hora-itinerario"><label>{t('registro')}: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('salida')}: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('llegada')}: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                                
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Domingo')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text7')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                             
                                    </div>
                                ))}

                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text18')}</h3>
                                        <div className="item-hora-itinerario"><label>{t('registro')}: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('salida')}: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('llegada')}: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            </>
                            )}
                            {selectedOption === 'Topolobampo - La Paz' &&(
                            <>                                

                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text1')}</h3>
                                        <div className="item-hora-itinerario"><label>{t('registro')}: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('salida')}: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('llegada')}: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Sabado')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text17')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}
                                
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Sabado')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text4')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}
                                
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Domingo')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text7')}</h3>
                                        <div className="item-hora-itinerario"><label>{t('registro')}: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('salida')}: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('llegada')}: </label><label>{viaje.duracion}</label> </div>                                                       
                                    </div>
                                ))}
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text18')}</h3>
                                        <div className="item-hora-itinerario"><label>{t('registro')}: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('salida')}: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('llegada')}: </label><label>{viaje.duracion}</label> </div>                                                       
                                    </div>
                                ))}
                            </>
                            )}
                            {selectedOption === 'La Paz - Mazatlan' &&(
                            <>                                
                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text20')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}          
                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Martes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text9')}</h3>
                                        <div className="item-hora-itinerario"><label>{t('registro')}: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('salida')}: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('llegada')}: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text10')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}
                                 {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text21')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}    

                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Martes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text19')}</h3>
                                        <div className="item-hora-itinerario"><label>{t('registro')}: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('salida')}: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('llegada')}: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                                
                               
                            </>
                            )}
                            {selectedOption === 'Mazatlan - La Paz' &&(
                            <>                                
                                {itinerario_MZ_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text22')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                                                                 
                                    </div>
                                ))}   

                                {itinerario_MZ_LP
                                .filter(viaje => viaje.dia === 'Viernes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text23')}</h3>
                                        <div className="item-hora-itinerario"><label>{t('registro')}: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('salida')}: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>{t('llegada')}: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_MZ_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text24')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                                                                 
                                    </div>
                                ))}  

                                {itinerario_MZ_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>{t('text18')}</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}                                                             
                            </>
                            )}
                       
                            <div>
                                <p>• Itinerario sujeto a cambios sin previo aviso.</p>
                            </div>
                        </div>                                                                        
                    </div>
                </div>
                <div className="box-servicio-horario">
                    <div className="available-services">                                
                        <h3>{t('text16')}</h3>
                        <Carousel logos={servicios} />                                                                                                     
                    </div>
                </div>
               
                <div className="content_mapa">
                    
                    {selectedOption === 'La Paz - Topolobampo' || selectedOption === 'Topolobampo - La Paz' ? (                                
                        <MapaTopo />                  
                    ):(
                        <MapaMaza />
                    )}
                </div>
                
               {/*
                <div className="container">
                    <div className="content-rutas-horario">
                        <div className="content_mapa">
                            <MapaCali />
                        </div>
                    </div>                    
                </div>
                */}
            </div>
        </section>
       

        <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={tarifas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>{t('cta1_title1')}</h4>
                                </div>
                                <div>
                                    <p>{t('cta_title1')} </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/tarifas">{t('cta_btn1')}</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={preguntas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>{t('cta1_title2')}</h4>
                                </div>
                                <div>
                                    <p>{t('cta_title2')} </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/preguntas-frecuentes">{t('cta_btn2')}</Link>     
                            </div>
                        </div>
                    </div>
                   {/*<div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Envianos un WhatsApp</h4>
                                </div>
                                <div>
                                    <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                            </div>
                        </div>
                    </div>*/}
                     <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title3')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title3')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">{t('cta_btn3')}  </a>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <Outlet />
        <Footer />
            
        
        </>
  )
}
