import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kaHJhIiwiYSI6ImNsbDJnZTJjbzJnMHAzZW15YnhhOWJkOGEifQ.pP3n_58agJfYHp7fUzrUbw';

const MapaMaza = () => {

  const mapContainerRef = useRef(null);
  const route = [
    [-110.327458, 24.275424],
    [-110.328072, 24.275111],
    [-110.328366, 24.274641],
    [-110.328538, 24.274014],
    [-110.328597, 24.273084],
    [-110.328597, 24.259600],
    [-110.328811, 24.258258],
    [-110.330110, 24.255444],
    [-110.331653, 24.253772],
    [-110.332387, 24.253132],
    [-110.333731, 24.252463],
    [-110.334770, 24.252059],
    [-110.336924, 24.251766],
    [-110.338910, 24.252003],
    [-110.342073, 24.253299],
    [-110.343020, 24.253995],
    [-110.344059, 24.255346],
    [-110.347146, 24.261782],
    [-110.356251, 24.309549],
    [-110.356099, 24.317430],
    [-110.354051, 24.327844],
    [-110.344762, 24.353541],
    [-110.343158, 24.356728],
    [-110.340851, 24.359052],
    [-110.321829, 24.371133],
    [-110.318101, 24.372859],
    [-110.093249, 24.449086],
    [-110.081607, 24.451826],
    [-110.069033, 24.453842],
    [-110.026880, 24.453216],
    [-109.636015, 24.463647],
    [-109.625122, 24.462034],
    [-109.617681, 24.458877],
    [-109.596337, 24.444743],
    [-109.004936, 23.983067],
    [-108.995515, 23.977638],
    [-108.983254, 23.972074],
    [-107.795299, 23.538176],
    [-106.498322, 23.160360],
    [-106.436466, 23.167502],
    [-106.420658, 23.177721],
    [-106.417849, 23.182754],
    [-106.417591, 23.183524],
    [-106.417545, 23.184760],
    [-106.417766, 23.186047],
    [-106.418135, 23.186411],
    [-106.418890, 23.186403],
    [-106.419784, 23.186174],
    [-106.420217, 23.186234],
  ];


  useEffect(() => {
    if (mapContainerRef.current) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11', // Estilo del mapa
        center: [-108.931, 25.177],
        zoom: 5.5, // Nivel de zoom inicial
      });

      // Agregar la ruta al mapa
      map.on('load', () => {
        map.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: route,
            },
          },
        });

        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#888',
            'line-width': 8,
          },
        });
         // Añadir marcadores al inicio y final de la ruta
            new mapboxgl.Marker()
            .setLngLat(route[0])
            .addTo(map);

            new mapboxgl.Marker()
            .setLngLat(route[route.length - 1])
            .addTo(map);
       

      });

      return () => map.remove();
    }
  });

  return <div ref={mapContainerRef} style={{ width: '100%', height: '100%', minHeight: '500px' }} />;
};

export default MapaMaza;