// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import headerEs from './es/header.json'
import headerEn from './en/header.json'
import headerFr from './fr/header.json'

import heroEs from './es/hero.json'
import heroEn from './en/hero.json'
import heroFr from './fr/hero.json'

import header_cargaEs from './es/header_carga.json'
import header_cargaEn from './en/header_carga.json'
import header_cargaFr from './fr/header_carga.json'

import homeEs from './es/home.json'
import homeEn from './en/home.json'
import homeFr from './fr/home.json'

import footerEs from './es/footer.json'
import footerEn from './en/footer.json'
import footerFr from './fr/footer.json'

import footer_cargaEs from './es/footer_carga.json'
import footer_cargaEn from './en/footer_carga.json'
import footer_cargaFr from './fr/footer_carga.json'

import reservaEs from './es/reserva.json'
import reservaEn from './en/reserva.json'
import reservaFr from './fr/reserva.json'

import nosotrosEs from './es/nosotros.json'
import nosotrosEn from './en/nosotros.json'
import nosotrosFr from './fr/nosotros.json'

import flotaEs from './es/flota.json'
import flotaEn from './en/flota.json'
import flotaFr from './fr/flota.json'

import servicioCaliforniaEs from './es/servicio.california.json'
import servicioCaliforniaEn from './en/servicio.california.json'
import servicioCaliforniaFr from './fr/servicio.california.json'

import servicioMexicoEs from './es/servicio.mexico.json'
import servicioMexicoEn from './en/servicio.mexico.json'
import servicioMexicoFr from './fr/servicio.mexico.json'

import certificacionesEs from './es/certificaciones.json';
import certificacionesEn from './en/certificaciones.json';
import certificacionesFr from './fr/certificaciones.json';

import solucionesLogisticasEs from './es/soluciones.logisticas.json';
import solucionesLogisticasEn from './en/soluciones.logisticas.json';
import solucionesLogisticasFr from './fr/soluciones.logisticas.json';

import RutasHorarioEs from './es/rutas.horario.json';
import RutasHorarioEn from './en/rutas.horario.json';
import RutasHorarioFr from './fr/rutas.horario.json';

import HorarioSantaEs from './es/horarios.semana.santa.json';
import HorarioSantaEn from './en/horarios.semana.santa.json';
import HorarioSantaFr from './fr/horarios.semana.santa.json';

import TarifasEs from './es/tarifas.json';
import TarifasEn from './en/tarifas.json';
import TarifasFr from './fr/tarifas.json';

import viajeGrupoEs from './es/viaje_grupo.json';
import viajeGrupoEn from './en/viaje_grupo.json';
import viajeGrupoFr from './fr/viaje_grupo.json';

import preparaViajeEs from './es/prepara.viaje.json'
import preparaViajeEn from './en/prepara.viaje.json'
import preparaViajeFr from './fr/prepara.viaje.json'

import destinosEs from './es/destinos.json'
import destinosEn from './en/destinos.json'
import destinosFr from './fr/destinos.json'


import LaPazEs from './es/LaPaz.json'
import LaPazEn from './en/LaPaz.json'
import LaPazFr from './fr/LaPaz.json'

import MazatlanEs from './es/mazatlan.json'
import MazatlanEn from './en/mazatlan.json'
import MazatlanFr from './fr/mazatlan.json'

import mochisEs from './es/mochis.json'
import mochisEn from './en/mochis.json'
import mochisFr from './fr/mochis.json'

import viaje_grupoEs from './es/viaje_grupo.json'
import viaje_grupoEn from './en/viaje_grupo.json'

import AntesDeViajarEs from './es/antesViajar.json'
import AntesDeViajarEn from './en/antesViajar.json'
import AntesDeViajarFr from './fr/antesViajar.json'

import duranteViajeEs from './es/duranteViaje.json'
import duranteViajeEn from './en/duranteViaje.json'
import duranteViajeFr from './fr/duranteViaje.json'

import al_llegarEs from './es/al_llegar.json'
import al_llegarEn from './en/al_llegar.json'
import al_llegarFr from './fr/al_llegar.json'

import contactoEs from './es/contacto.json'
import contactoEn from './en/contacto.json'
import contactoFr from './fr/contacto.json'

import preguntasFrecuentesEs from './es/preguntas.frecuentes.json'
import preguntasFrecuentesEn from './en/preguntas.frecuentes.json'
import preguntasFrecuentesFr from './fr/preguntas.frecuentes.json'

import pf_antes_viajarEs from './es/pf.antesViajar.json'
import pf_antes_viajarEn from './en/pf.antesViajar.json'
import pf_antes_viajarFr from './fr/pf.antesViajar.json'

import respuestaAntesViajarEs from './es/respuestaAntesViajar.json'
import respuestaAntesViajarEn from './en/respuestaAntesViajar.json'
import respuestaAntesViajarFr from './fr/respuestaAntesViajar.json'

import pf_reservaEs from './es/pf.reserva.json'
import pf_reservaEn from './en/pf.reserva.json'
import pf_reservaFr from './fr/pf.reserva.json'

import pf_documentacionEs from './es/pf.documentacion.json'
import pf_documentacionEn from './en/pf.documentacion.json'
import pf_documentacionFr from './fr/pf.documentacion.json'

import pf_pagosEs from './es/pf_pagos.facturas.json'
import pf_pagosEn from './en/pf_pagos.facturas.json'
import pf_pagosFr from './fr/pf_pagos.facturas.json'

import pf_autosEs from './es/pf_automovil.json'
import pf_autosEn from './en/pf_automovil.json'
import pf_autosFr from './fr/pf_automovil.json'

import pf_cargaEs from './es/pf_carga.json'
import pf_cargaEn from './en/pf_carga.json'
import pf_cargaFr from './fr/pf_carga.json'

import aviso_privacidadEs from './es/aviso_privacidad.json'
import aviso_privacidadEn from './en/aviso_privacidad.json'

import condicionesEs from './es/condiciones.json'
import condicionesEn from './en/condiciones.json'

import maritimoEs from './es/maritimo.json'
import maritimoEn from './en/maritimo.json'
import maritimoFr from './fr/maritimo.json'

import terrestreEs from './es/terrestre.json'
import terrestreEn from './en/terrestre.json'
import terrestreFr from './fr/terrestre.json'

import multimodalEs from './es/multimodal.json'
import multimodalEn from './en/multimodal.json'
import multimodalFr from './fr/multimodal.json'

import integracionLogisticaEs from './es/integraciones_logisticas.json'
import integracionLogisticaEn from './en/integraciones_logisticas.json'
import integracionLogisticaFr from './fr/integraciones_logisticas.json'

import rutas_horario_cargaEs from './es/ruta_horario_carga.json'
import rutas_horario_cargaEn from './en/ruta_horario_carga.json'
import rutas_horario_cargaFr from './fr/ruta_horario_carga.json'

import tarifasCargaEs from './es/tarifas_carga.json'
import tarifasCargaEn from './en/tarifas_carga.json'
import tarifasCargaFr from './fr/tarifas_carga.json'

import promocionesEs from './es/promociones.json'
import promocionesEn from './en/promociones.json'
import promocionesFr from './fr/promociones.json'

import itinerarioEs from './es/itinerario.json'
import itinerarioEn from './en/itinerario.json'
import itinerarioFr from './fr/itinerario.json'

import promo1Es from './es/promo1.json'
import promo1En from './en/promo1.json'
import promo1Fr from './fr/promo1.json'

import promo4Es from './es/promo4.json'
import promo4En from './en/promo4.json'
import promo4Fr from './fr/promo4.json'

import promo3Es from './es/promo3.json'
import promo3En from './en/promo3.json'
import promo3Fr from './fr/promo3.json'

import promo8Es from './es/promo8.json'
import promo8En from './en/promo8.json'
import promo8Fr from './fr/promo8.json'

import promo10Es from './es/promo10.json'
import promo10En from './en/promo10.json'
import promo10Fr from './fr/promo10.json'

i18n
  .use(initReactI18next)
  .init({
    lng: 'es', // Idioma predeterminado
    fallbackLng: 'en', // Idioma de respaldo
    ns: ['nosotrosEs', 'nosotrosEn', 'nosotrosFr', 'flotaEs', 'flotaEn', 'flotaFr', 'heroEs', 'heroEn', 'heroFr',
      'servicioCaliforniaEs','servicioCaliforniaEn', 'servicioCaliforniaFr','servicioMexicoEs','servicioMexicoEn', 'servicioMexicoFr','certificacionesEs', 
      'certificacionesEn', 'certificacionesFr', 'solucionesLogisticasEn','solucionesLogisticasEs','RutasHorarioEs', 'HorarioSantaEs','HorarioSantaEn','HorarioSantaFr','RutasHorarioEn','RutasHorarioFr',
      'TarifasEs','TarifasEn','TarifasFr','viajeGrupoEs','viajeGrupoEn','viajeGrupoFr', 'preparaViajeEs','preparaViajeEn','preparaViajeFr', 'destinosEs',
      'destinosEn','destinosFr','LaPazEs', 'LaPazEn','LaPazFr','MazatlanEs','MazatlanFr','MazatlanEn','mochisEs','mochisEs','mochisEf','viaje_grupoEs','viaje_grupoEn',
      'AntesDeViajarEs','AntesDeViajarEn','AntesDeViajarFr','duranteViajeEs', 'duranteViajeEn','duranteViajeFr','al_llegarEs','al_llegarEn','al_llegarFr',
      'contactoEs','contactoEn','contactoFr','preguntasFrecuentesEs','preguntasFrecuentesEn','preguntasFrecuentesFr','pf_antes_viajarEs','pf_antes_viajarEn','pf_antes_viajarFr',
      'respuestaAntesViajarEs','respuestaAntesViajarEn','respuestaAntesViajarFr','pf_reservaEs','pf_reservaEn','pf_reservaFr','pf_documentacionEs','pf_documentacionFr','pf_documentacionEn',
      'pf_pagosEs', 'pf_pagosFr','pf_pagosEn','pf_autosEs','pf_autosFr', 'pf_autosEn','pf_cargaEs','pf_cargaFr','pf_cargaEn','aviso_privacidadEs',
      'aviso_privacidadEn','condicionesEs','condicionesEn','maritimoEs','maritimoEn','maritimoFr','terrestreEs','terrestreEn','terrestreFr',
      'multimodalEs', 'multimodalEn','multimodalFr','integracionLogisticaEs', 'integracionLogisticaFr', 'integracionLogisticaEn','rutas_horario_cargaEs','rutas_horario_cargaFr',
      'rutas_horario_cargaEn','tarifasCargaEs','tarifasCargaFr','tarifasCargaEn','headerEs','headerFr','headerEn','homeEs','homeFr','homeEn','footerEs','footerFr','footerEn',
      'footer_cargaEs','footer_cargaFr','footer_cargaEn','header_cargaEs','header_cargaFr','header_cargaEn','reservaEs','reservaFr','reservaEn','promocionesEs','promocionesFr',
      'promocionesEn','itinerarioEs','itinerarioEn','itinerarioFr','promo1Es','promo1En','promo1Fr','promo4Es','promo4En','promo4Fr','promo3Es','promo3En','promo3Fr',
    'promo8Es','promo8En','promo8Fr','promo10Es','promo10En', 'promo10Fr'], // Lista de namespaces

    defaultNS: 'common', // Namespace predeterminado
    resources: {
      es: {
        nosotros: nosotrosEs,
        hero: heroEs,
        flota: flotaEs,
        servicioCalifornia: servicioCaliforniaEs,
        servicioMexico: servicioMexicoEs,
        certificacion: certificacionesEs,
        solucionesLogisticas: solucionesLogisticasEs,
        RrutasHorario:RutasHorarioEs,
        HorarioSanta:HorarioSantaEs,
        tarifas:TarifasEs,
        viajeGrupo: viajeGrupoEs,
        preparaViaje: preparaViajeEs,
        destinos: destinosEs,
        LaPaz: LaPazEs,
        Mazatlan: MazatlanEs,
        mochis:mochisEs,
        viaje_grupo:viaje_grupoEs,
        antesViajar:AntesDeViajarEs,
        duranteViaje:duranteViajeEs,
        al_llegar:al_llegarEs,
        contacto:contactoEs,
        preguntasFrecuente:preguntasFrecuentesEs,
        pf_antesViajar:pf_antes_viajarEs,
        respuestaAntesViajar:respuestaAntesViajarEs,
        pf_reserva:pf_reservaEs,
        pf_documentacion:pf_documentacionEs,
        pf_pagos: pf_pagosEs,
        pf_autos: pf_autosEs,
        pf_carga: pf_cargaEs,
        aviso_privacidad:aviso_privacidadEs,
        condiciones:condicionesEs,
        maritimo:maritimoEs,
        terrestre:terrestreEs,
        multimodal:multimodalEs,
        integracionLogistica:integracionLogisticaEs,
        rutas_horario_carga:rutas_horario_cargaEs,
        tarifasCarga:tarifasCargaEs,
        header: headerEs,
        home:homeEs,
        footer:footerEs,
        footer_carga: footer_cargaEs,
        header_carga:header_cargaEs,
        reserva:reservaEs,
        promociones:promocionesEs,
        itinerario: itinerarioEs,
        promo1: promo1Es,
        promo4:promo4Es,
        promo3:promo3Es,
        promo8:promo8Es,
        promo10: promo10Es,
      },
      en: {
        nosotros: nosotrosEn,
        hero: heroEn,
        flota: flotaEn,
        servicioCalifornia: servicioCaliforniaEn,
        servicioMexico: servicioMexicoEn,
        certificacion: certificacionesEn,
        solucionesLogisticas: solucionesLogisticasEn,
        RrutasHorario:RutasHorarioEn,
        HorarioSanta:HorarioSantaEn,
        tarifas:TarifasEn,
        viajeGrupo:viajeGrupoEn,
        preparaViaje: preparaViajeEn,
        destinos: destinosEn,
        LaPaz: LaPazEn,
        Mazatlan: MazatlanEn,
        mochis:mochisEn,
        viaje_grupo:viaje_grupoEn,
        antesViajar:AntesDeViajarEn,
        duranteViaje:duranteViajeEn,
        al_llegar:al_llegarEn,
        contacto:contactoEn,
        preguntasFrecuente:preguntasFrecuentesEn,
        pf_antesViajar:pf_antes_viajarEn,
        respuestaAntesViajar:respuestaAntesViajarEn,
        pf_reserva:pf_reservaEn,
        pf_documentacion:pf_documentacionEn,
        pf_pagos: pf_pagosEn,
        pf_autos: pf_autosEn,
        pf_carga: pf_cargaEn,
        aviso_privacidad:aviso_privacidadEn,
        condiciones:condicionesEn,
        maritimo:maritimoEn,
        terrestre:terrestreEn,
        multimodal:multimodalEn,
        integracionLogistica:integracionLogisticaEn,
        rutas_horario_carga:rutas_horario_cargaEn,
        tarifasCarga:tarifasCargaEn,
        header: headerEn,
        home:homeEn,
        footer:footerEn,
        footer_carga: footer_cargaEn,
        header_carga:header_cargaEn,
        reserva:reservaEn,
        promociones:promocionesEn,
        itinerario: itinerarioEn,
        promo1: promo1En,
        promo4: promo4En,
        promo3: promo3En,
        promo8: promo8En,
        promo10: promo10En,
      },
      fr: {
        nosotros: nosotrosFr, 
        hero: heroFr,
        flota: flotaFr,     
        servicioCalifornia: servicioCaliforniaFr , 
        servicioMexico: servicioMexicoFr, 
        certificacion: certificacionesFr,  
        solucionesLogisticas: solucionesLogisticasFr, 
        maritimo:maritimoFr,
        terrestre:terrestreFr,
        multimodal:multimodalFr,
        integracionLogistica:integracionLogisticaFr,   
        RrutasHorario:RutasHorarioFr, 
        HorarioSanta:HorarioSantaFr, 
        tarifas:TarifasFr,
        viajeGrupo:viajeGrupoFr,
        preparaViaje: preparaViajeFr,
        destinos: destinosFr,
        LaPaz: LaPazFr,
        Mazatlan: MazatlanFr,
        mochis:mochisFr,
        antesViajar:AntesDeViajarFr,
        duranteViaje:duranteViajeFr,
        al_llegar:al_llegarFr,
        contacto:contactoFr,
        preguntasFrecuente:preguntasFrecuentesFr,
        pf_antesViajar:pf_antes_viajarFr,
        respuestaAntesViajar:respuestaAntesViajarFr,
        pf_reserva:pf_reservaFr,
        pf_documentacion:pf_documentacionFr,
        pf_pagos: pf_pagosFr,
        pf_autos: pf_autosFr,
        pf_carga: pf_cargaFr,
        rutas_horario_carga:rutas_horario_cargaFr,
        tarifasCarga:tarifasCargaFr,
        promociones:promocionesFr,
        header: headerFr,
        header_carga:header_cargaFr,
        home:homeFr,
        footer:footerFr,
        footer_carga: footer_cargaFr,
        reserva:reservaFr,
        itinerario: itinerarioFr,
        promo1: promo1Fr,
        promo4:promo4Fr,
        promo3:promo3Fr,
        promo8:promo8Fr,
        promo10: promo10Fr,
      },
    }
  });

export default i18n;


